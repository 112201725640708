import React from "react"
import Layout from "../components/Layout"
import Section from "../components/Section"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import docIcon from "../images/icons/doc-logo.svg"
import graphIcon from "../images/icons/graph-logo.svg"
import addressIcon from "../images/icons/contact-address.svg"
import numberIcon from "../images/icons/contact-phone.svg"
import emailIcon from "../images/icons/contact-email.svg"
import moneySproutMobile from "../images/moneysprout--mobile.jpg"
import { Envelope, GeoAlt, Telephone } from "react-bootstrap-icons"
import "./Home.scss"

const intro = {
    title: "Success begins with a plan.",
    start: "Life’s complicated, but there is a simple path to success.",
    step1: "Get organized. Make a plan. Save and invest. Track your progress.",
    qualifier:
        "This path is \"simple,” but not necessarily easy. Peering into the future and planning ahead takes effort and discipline. That's where an organized process and a savvy plan can make all the difference. You begin with incremental steps, but your rewards compound exponentially.",
    qualifier2:
        "But of course, money itself is only the means to an end. Making the most of your finances is ultimately about enabling you to live your best life.",
    close: "Let us help you live your best life!",
}

const whatWeDo = {
    title: "What we do",
    subSection1Title: "Financial planning",
    subSection1Text:
        "Comprehensive financial planning can help you clarify your goals, make the most of your finances, and help you live your best life. The process begins with understanding your objectives and getting organized, then walks through every dimension of your financial life. The process also builds a powerful sense of confidence and security that comes from having a solid long-term plan and from having a savvy financial coach available on-demand.",
    subSection2Title: "Investment management",
    subSection2Text:
        "Value focused, tax efficient, risk managed – these are the pillars of savvy investments. And now more than ever, you need to know where your investments stand. Are your investments structured to weather extreme volatility?  Are you simultaneously looking out for long-term opportunities? Savvy can help you review where your investments are at today, and make sound recommendations for long-term success.",
}

const consultation = {
    title: "Complimentary consultation",
    p:
        "If you’d like to explore the possibilities, we’d be happy to offer a complimentary consultation to review your current financial health, or to discuss any particular financial concerns you may have. No committment, no obligation. Just happy to get acquainted and share some insights.",
}

const learnMore = "Learn more ➝"

export default function Home({ data }) {
    return (
        <div>
            <Layout activeTab="home">
                <div className="home__banner">
                    <div className="home__banner-img">
                        <Img
                            fluid={data.file.childImageSharp.fluid}
                            alt="a pile of coins sprouting a seedling"
                        />
                    </div>

                    <img
                        className="home__banner--mobile"
                        src={moneySproutMobile}
                        alt="a pile of coins sprouting a seedling"
                    />

                    <Section
                        className="home__intro text-content"
                        title={intro.title}
                        hideUnderline={true}
                        size="lg"
                    >
                        <div className="home__intro-content">{intro.start}</div>
                        <div className="home__intro-content">{intro.step1}</div>
                        <div className="home__intro-content">
                            {intro.qualifier}
                        </div>
                        <div className="home__intro-content">
                            {intro.qualifier2}
                        </div>
                        <div className="home__intro-content home__intro-content--close">
                            {intro.close}
                        </div>
                    </Section>
                </div>
                <div className="home__page-content">
                    <Section
                        className="home__what-we-do"
                        title={whatWeDo.title}
                        alignment="center"
                        size="md"
                        hideUnderline={true}
                    >
                        <div className="home__what-we-do-subsections">
                            <div className="home__what-we-do-subsection-icons--mobile">
                                <img src={docIcon} alt="planning icon" />
                                <img src={graphIcon} alt="investments icon" />
                            </div>
                            <div className="home__what-we-do-subsection">
                                <img
                                    className="home__what-we-do-subsection-icon"
                                    src={docIcon}
                                    alt="planning icon"
                                />
                                <div className="home__what-we-do-subsection-content">
                                    <div className="home__what-we-do-subsection-title text-title">
                                        {whatWeDo.subSection1Title}
                                    </div>
                                    <div className="home__what-we-do-subsection-text text-content">
                                        {whatWeDo.subSection1Text}
                                    </div>
                                    <Link
                                        className="home__what-we-do-link text-content"
                                        to="/planning"
                                    >
                                        {learnMore}
                                    </Link>
                                </div>
                            </div>
                            <div className="home__what-we-do-subsection">
                                <img
                                    className="home__what-we-do-subsection-icon"
                                    src={graphIcon}
                                    alt="investments icon"
                                />
                                <div className="home__what-we-do-subsection-content">
                                    <div className="home__what-we-do-subsection-title">
                                        {whatWeDo.subSection2Title}
                                    </div>
                                    <div className="home__what-we-do-subsection-text">
                                        {whatWeDo.subSection2Text}
                                    </div>
                                    <Link
                                        className="home__what-we-do-link"
                                        to="/investments"
                                    >
                                        {learnMore}
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </Section>
                    <Section
                        title={consultation.title}
                        className="home__consultation"
                        hideUnderline={true}
                        size="sm"
                        alignment="center"
                    >
                        <div className="home__consultation-text">
                            {consultation.p}
                        </div>
                        <div className="consultation__contact" id="contact">
                            <span className="consultation__contact-icon-wrapper">
                                <GeoAlt size={35} />
                                <img
                                    className="consultation__contact-icon"
                                    src={addressIcon}
                                    alt="address contact information"
                                />
                            </span>
                            <span className="consultation__contact-icon-wrapper">
                                <Telephone size={35} />
                                <img
                                    className="consultation__contact-icon"
                                    src={numberIcon}
                                    alt="phone contact information"
                                />
                            </span>
                            <span className="consultation__contact-icon-wrapper">
                                <Envelope size={35} />
                                <img
                                    className="consultation__contact-icon consultation__contact-icon--email"
                                    src={emailIcon}
                                    alt="email contact information"
                                />
                            </span>
                        </div>
                    </Section>
                </div>
            </Layout>
        </div>
    )
}

export const query = graphql`
    query MyQuery {
        file(relativePath: { eq: "moneysprout--transparent.png" }) {
            childImageSharp {
                fluid(quality: 100) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
    }
`
